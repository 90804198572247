import React, { lazy } from 'react'

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)

const BankListing = lazy(() =>
  import('./Payroll/Bank/BankListing').then(module => ({
    default: module.BankListing,
  }))
)

const PayslipListing = lazy(() =>
  import('./Payroll/Payslip/PayslipListing').then(module => ({
    default: module.PayslipListing,
  }))
)

const GovernmentListingKWSP = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentListingKWSP').then(
    module => ({
      default: module.GovernmentListingKWSP,
    })
  )
)

const GovernmentListingSOCSO = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentListingSOCSO').then(
    module => ({
      default: module.GovernmentListingSOCSO,
    })
  )
)

const GovernmentListingEIS = lazy(() =>
  import('./Payroll/GovernmentListingEIS/GovernmentListingEIS').then(
    module => ({
      default: module.GovernmentListingEIS,
    })
  )
)

const EISText = lazy(() =>
  import('./Payroll/GovernmentListingEIS/EISText').then(module => ({
    default: module.EISText,
  }))
)

const GovernmentListingLHDN = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/GovernmentListingLHDN').then(
    module => ({
      default: module.GovernmentListingLHDN,
    })
  )
)

const BankIntegrationFiles = lazy(() =>
  import('./Payroll/BankIntegrationFiles/BankIntegrationFiles').then(
    module => ({
      default: module.BankIntegrationFiles,
    })
  )
)

const BankIntegrationFilesListing = lazy(() =>
  import('./Payroll/BankIntegrationFiles/BankIntegrationFilesListing').then(
    module => ({
      default: module.BankIntegrationFilesListing,
    })
  )
)

const PayrollListing = lazy(() =>
  import('./Payroll/PayrollListing/PayrollListing').then(module => ({
    default: module.PayrollListing,
  }))
)

// HSBC Bank - Bank Integration File
const HSBCSalaryText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HSBC/HSBCSalaryText').then(module => ({
    default: module.HSBCSalaryText,
  }))
)

// Bank Islam - Bank Integration File
const BIMBSalaryText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/BIMB/BIMBSalaryText').then(module => ({
    default: module.BIMBSalaryText,
  }))
)

//HL Bank - Bank Integration File

const HLBBSalaryExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBSalaryExcel').then(
    module => ({
      default: module.HLBBSalaryExcel,
    })
  )
)

const HLBBEPFExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBEPFExcel').then(module => ({
    default: module.HLBBEPFExcel,
  }))
)

const HLBBLHDNExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBLHDNExcel').then(module => ({
    default: module.HLBBLHDNExcel,
  }))
)

const HLBBSOCSOExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBSOCSOExcel').then(module => ({
    default: module.HLBBSOCSOExcel,
  }))
)

const HLBBTabungHajiExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBTabungHajiExcel').then(
    module => ({
      default: module.HLBBTabungHajiExcel,
    })
  )
)

const HLBBZakatExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/HLBB/HLBBZakatExcel').then(module => ({
    default: module.HLBBZakatExcel,
  }))
)
//RHB bank - Bank Integration File
const RHBExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHBExcel').then(module => ({
    default: module.RHBExcel,
  }))
)

const RHBIGBExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHBIGBExcel').then(module => ({
    default: module.RHBIGBExcel,
  }))
)

const RHBEPFExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHBEPFExcel').then(module => ({
    default: module.RHBEPFExcel,
  }))
)

const RHBEISExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHBEISExcel').then(module => ({
    default: module.RHBEISExcel,
  }))
)

const RHBLHDNExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHBLHDNExcel').then(module => ({
    default: module.RHBLHDNExcel,
  }))
)

const CIMBSalaryDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBSalaryDownloadText').then(module => ({
    default: module.CIMBSalaryDownloadText,
  }))
)

const CIMBEPFDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBEPFDownloadText').then(module => ({
    default: module.CIMBEPFDownloadText,
  }))
)

const CIMBLHDNDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBLHDNDownloadText').then(module => ({
    default: module.CIMBLHDNDownloadText,
  }))
)

const CIMBSOCSODownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBSOCSODownloadText').then(module => ({
    default: module.CIMBSOCSODownloadText,
  }))
)

const CIMBEISDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/CIMB/CIMBEISDownloadText').then(module => ({
    default: module.CIMBEISDownloadText,
  }))
)

const RHBSOCSOExcel = lazy(() =>
  import('./Payroll/BankIntegrationFiles/RHBSOCSOExcel').then(module => ({
    default: module.RHBSOCSOExcel,
  }))
)

//Payroll - Form
const Lampiran1 = lazy(() =>
  import('./Payroll/GovernmentListingEIS/Lampiran1').then(module => ({
    default: module.Lampiran1,
  }))
)

const BorangA = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/BorangA').then(module => ({
    default: module.BorangA,
  }))
)

const GovernmentEPF = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentEPF').then(module => ({
    default: module.GovernmentEPF,
  }))
)

const GovernmentEPFText = lazy(() =>
  import('./Payroll/GovernmentListingKWSP/GovernmentEPFText').then(module => ({
    default: module.GovernmentEPFText,
  }))
)

const BorangEListing = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE/BorangEListing').then(
    module => ({
      default: module.BorangEListing,
    })
  )
)

const BorangE2 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE/BorangE2').then(
    module => ({
      default: module.BorangE2,
    })
  )
)

const BorangE = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangE').then(module => ({
    default: module.BorangE,
  }))
)

const BorangEAListing = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEAListing').then(
    module => ({
      default: module.BorangEAListing,
    })
  )
)

const BorangEA2 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA/BorangEA2').then(
    module => ({
      default: module.BorangEA2,
    })
  )
)

const BorangEA = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/BorangEA').then(module => ({
    default: module.BorangEA,
  }))
)

const CP159 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP159').then(module => ({
    default: module.CP159,
  }))
)

const CP21 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP21').then(module => ({
    default: module.CP21,
  }))
)

const CP22 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22').then(module => ({
    default: module.CP22,
  }))
)

const CP22A = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP22A').then(module => ({
    default: module.CP22A,
  }))
)

const CP39 = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP39').then(module => ({
    default: module.CP39,
  }))
)

const CP39A = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP39A').then(module => ({
    default: module.CP39A,
  }))
)

const CP8D = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP8D').then(module => ({
    default: module.CP8D,
  }))
)

const CP8DText = lazy(() =>
  import('./Payroll/GovernmentListingLHDN/CP8DText').then(module => ({
    default: module.CP8DText,
  }))
)

const Borang8A = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang8A').then(module => ({
    default: module.Borang8A,
  }))
)

const Borang3 = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang3').then(module => ({
    default: module.Borang3,
  }))
)

const Borang2 = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/Borang2').then(module => ({
    default: module.Borang2,
  }))
)

const GovernmentSOCSO = lazy(() =>
  import('./Payroll/GovernmentListingSOCSO/GovernmentSOCSO').then(module => ({
    default: module.GovernmentSOCSO,
  }))
)

const ViewPayslip = lazy(() =>
  import('./Payroll/Payslip/ViewPayslip').then(module => ({
    default: module.ViewPayslip,
  }))
)

const HRDFFundListing = lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundListing').then(module => ({
    default: module.HRDFFundListing,
  }))
)

const HRDFFundView = lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundView').then(module => ({
    default: module.HRDFFundView,
  }))
)

const ZakatListing = lazy(() =>
  import('./Payroll/ZakatListing/ZakatListing').then(module => ({
    default: module.ZakatListing,
  }))
)

const ZakatListingView = lazy(() =>
  import('./Payroll/ZakatListing/ZakatListingView').then(module => ({
    default: module.ZakatListingView,
  }))
)

const HRDFFundListingByCompany= lazy(() =>
  import('./Payroll/HRDFFundListing/HRDFFundListingByCompany').then(module => ({
    default: module.HRDFFundListingByCompany,
  }))
)

//Payroll - Report
const EmployeePayHistory = lazy(() =>
  import('./Payroll/PayrollListing/EmployeePayHistory').then(module => ({
    default: module.EmployeePayHistory,
  }))
)

const EmployeeSalary = lazy(() =>
  import('./Payroll/PayrollListing/EmployeeSalary').then(module => ({
    default: module.EmployeeSalary,
  }))
)

const HistoryTransSummary = lazy(() =>
  import('./Payroll/PayrollListing/HistoryTransummary').then(module => ({
    default: module.HistoryTransummary,
  }))
)

const MonthlyTransactionDetail = lazy(() =>
  import('./Payroll/PayrollListing/MonthlyTransactionDetail').then(module => ({
    default: module.MonthlyTransactionDetail,
  }))
)

const BankPayoutListing = lazy(() =>
  import('./Payroll/Bank/BankPayoutListing').then(module => ({
    default: module.BankPayoutListing,
  }))
)

const CashChequePayoutListing = lazy(() =>
  import('./Payroll/Bank/CashChequePayoutListing').then(module => ({
    default: module.CashChequePayoutListing,
  }))
)

const PayrollDetail = lazy(() =>
  import('./Payroll/PayrollListing/PayrollDetail').then(module => ({
    default: module.PayrollDetail,
  }))
)

const PayrollVarianceSummary = lazy(() =>
  import('./Payroll/PayrollListing/PayrollVarianceSummary').then(module => ({
    default: module.PayrollVarianceSummary,
  }))
)

const PayrollTransactionDetailsListing = lazy(() =>
  import('./Payroll/PayrollListing/PayrollTransactionDetailsListing').then(
    module => ({
      default: module.PayrollTransactionDetailsListing,
    })
  )
)

const SalaryPayoutAnalysisDetail = lazy(() =>
  import('./Payroll/PayrollListing/SalaryPayoutAnalysisDetail').then(
    module => ({
      default: module.SalaryPayoutAnalysisDetail,
    })
  )
)

const SalaryPayoutAnalysisSummary = lazy(() =>
  import('./Payroll/PayrollListing/SalaryPayoutAnalysisSummary').then(
    module => ({
      default: module.SalaryPayoutAnalysisSummary,
    })
  )
)

const TransactionDetail = lazy(() =>
  import('./Payroll/PayrollListing/TransactionDetail').then(module => ({
    default: module.TransactionDetail,
  }))
)

// UOB
const UOBSalaryDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBSalaryDownloadText').then(module => ({
    default: module.UOBSalaryDownloadText,
  }))
)

const UOBEISText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBEISText').then(module => ({
    default: module.UOBEISText,
  }))
)

const UOBSOCSOText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBSOCSOText').then(module => ({
    default: module.UOBSOCSOText,
  }))
)

const UOBEPFText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/UOB/UOBEPFText').then(module => ({
    default: module.UOBEPFText,
  }))
)

// MAYBANK
const M2UBizSalaryDownloadCsv = lazy(() =>
  import('./Payroll/BankIntegrationFiles/MAYBANK/M2UBizSalaryDownloadCsv').then(module => ({
    default: module.M2UBizSalaryDownloadCsv,
  }))
)
const M2ERCSalaryDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/MAYBANK/M2ERCSalaryDownloadText').then(module => ({
    default: module.M2ERCSalaryDownloadText,
  }))
)
const M2ERCEPFDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/MAYBANK/M2ERCEPFDownloadText').then(module => ({
    default: module.M2ERCEPFDownloadText,
  }))
)
const M2ERCTaxDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/MAYBANK/M2ERCTaxDownloadText').then(module => ({
    default: module.M2ERCTaxDownloadText,
  }))
)
const M2ERCSOCSODownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/MAYBANK/M2ERCSOCSODownloadText').then(module => ({
    default: module.M2ERCSOCSODownloadText,
  }))
)
const M2ERCEISDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/MAYBANK/M2ERCEISDownloadText').then(module => ({
    default: module.M2ERCEISDownloadText,
  }))
)
const M2ERCZakatDownloadText = lazy(() =>
  import('./Payroll/BankIntegrationFiles/MAYBANK/M2ERCZakatDownloadText').then(module => ({
    default: module.M2ERCZakatDownloadText,
  }))
)

//Personnel - Report
const EmployeeBasicInfo = lazy(() =>
  import('./Personnel/EmployeeBasicInfo').then(module => ({
    default: module.EmployeeBasicInfo,
  }))
)

const EmployeeConfirmation = lazy(() =>
  import('./Personnel/EmployeeConfirmation').then(module => ({
    default: module.EmployeeConfirmation,
  }))
)

const EmployeeDependent = lazy(() =>
  import('./Personnel/EmployeeDependent').then(module => ({
    default: module.EmployeeDependent,
  }))
)

const EmployeeEducation = lazy(() =>
  import('./Personnel/EmployeeEducation').then(module => ({
    default: module.EmployeeEducation,
  }))
)

const EmployeeNewJoin = lazy(() =>
  import('./Personnel/EmployeeNewJoin').then(module => ({
    default: module.EmployeeNewJoin,
  }))
)

const EmployeeResignation = lazy(() =>
  import('./Personnel/EmployeeResignation').then(module => ({
    default: module.EmployeeResignation,
  }))
)

const LeaveAnalysis = lazy(() =>
  import('./Leave/LeaveAnalysis').then(module => ({
    default: module.LeaveAnalysis,
  }))
)

const TimeAttendanceAnalysis = lazy(() =>
  import('./TimeAttendance/TimeAttendanceAnalysis').then(module => ({
    default: module.TimeAttendanceAnalysis,
  }))
)

//CLAIM

const BankClaimIntegrationFiles = lazy(() =>
  import('./Claim/BankClaimIntegrationFiles/BankClaimIntegrationFiles').then(
    module => ({
      default: module.BankClaimIntegrationFiles,
    })
  )
)

const ClaimBalanceAnalysis = lazy(() =>
  import('./Claim/ClaimBalanceAnalysis/ClaimBalanceAnalysis').then(module => ({
    default: module.ClaimBalanceAnalysis,
  }))
)

const ClaimStatusAnalysis = lazy(() =>
  import('./Claim/ClaimStatusAnalaysis/ClaimStatusAnalysis').then(module => ({
    default: module.ClaimStatusAnalysis,
  }))
)

const StaffClaim = lazy(() =>
  import('./Claim/StaffClaim/StaffClaim').then(module => ({
    default: module.StaffClaim,
  }))
)

const BankClaimIntegrationFilesListing = lazy(() =>
  import(
    './Claim/BankClaimIntegrationFiles/BankClaimIntegrationFilesListing'
  ).then(module => ({
    default: module.BankClaimIntegrationFilesListing,
  }))
)

const HLBBClaimExcel = lazy(() =>
  import('./Claim/BankClaimIntegrationFiles/HLBB/HLBBClaimExcel').then(
    module => ({
      default: module.HLBBClaimExcel,
    })
  )
)
const HomeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home/payroll/bank' },
    component: <BankListing />,
  },
  {
    props: { exact: true, path: '/home/payroll/payrolllisting' },
    component: <PayrollListing />,
  },

  //Payroll - Report
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/employeepayhistory',
    },
    component: <EmployeePayHistory />,
  },
  {
    props: { exact: true, path: '/home/payroll/payrolllisting/employeesalary' },
    component: <EmployeeSalary />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/historytransSummary',
    },
    component: <HistoryTransSummary />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/monthlytransactiondetail',
    },
    component: <MonthlyTransactionDetail />,
  },
  {
    props: { exact: true, path: '/home/payroll/bank/bankpayoutlisting' },
    component: <BankPayoutListing />,
  },
  {
    props: { exact: true, path: '/home/payroll/bank/cashchequepayoutlisting' },
    component: <CashChequePayoutListing />,
  },
  {
    props: { exact: true, path: '/home/payroll/payrolllisting/payrolldetail' },
    component: <PayrollDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/payrollvariancesummary',
    },
    component: <PayrollVarianceSummary />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/payrolltransactiondetailslisting',
    },
    component: <PayrollTransactionDetailsListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/salarypayoutanalysisdetail',
    },
    component: <SalaryPayoutAnalysisDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/salarypayoutanalysissummary',
    },
    component: <SalaryPayoutAnalysisSummary />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/payrolllisting/transactiondetail',
    },
    component: <TransactionDetail />,
  },

  //Payroll - Form
  {
    props: { exact: true, path: '/home/payroll/paysliplisting' },
    component: <PayslipListing />,
  },
  {
    props: { exact: true, path: '/home/payroll/paysliplisting/viewpayslip' },
    component: <ViewPayslip />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingKWSP' },
    component: <GovernmentListingKWSP />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingKWSP/BorangA' },
    component: <BorangA />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingKWSP/GovernmentEPF',
    },
    component: <GovernmentEPF />,
  },

  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingKWSP/GovernmentEPFText',
    },
    component: <GovernmentEPFText />,
  },

  {
    props: { exact: true, path: '/home/payroll/GovernmentListingSOCSO' },
    component: <GovernmentListingSOCSO />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingSOCSO/Borang8A',
    },
    component: <Borang8A />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingSOCSO/Borang3',
    },
    component: <Borang3 />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingSOCSO/Borang2',
    },
    component: <Borang2 />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingSOCSO/GovernmentSOCSO',
    },
    component: <GovernmentSOCSO />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingEIS' },
    component: <GovernmentListingEIS />,
  },

  {
    props: { exact: true, path: '/home/payroll/GovernmentListingEIS/EISText' },
    component: <EISText />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingEIS/Lampiran1',
    },
    component: <Lampiran1 />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN' },
    component: <GovernmentListingLHDN />,
  },

  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/BorangEListing'},
    component: <BorangEListing/>,
  },

  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/BorangE2'},
    component: <BorangE2/>,
  },

  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/BorangE' },
    component: <BorangE />,
  },

  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/BorangEAListing'},
    component: <BorangEAListing/>,
  },

  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingLHDN/BorangEA2',
    },
    component: <BorangEA2 />,
  },

  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingLHDN/BorangEA',
    },
    component: <BorangEA />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/CP8D' },
    component: <CP8D />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/GovernmentListingLHDN/CP8DText',
    },
    component: <CP8DText />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/CP21' },
    component: <CP21 />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/CP22' },
    component: <CP22 />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/CP22A' },
    component: <CP22A />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/CP39' },
    component: <CP39 />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/CP39A' },
    component: <CP39A />,
  },
  {
    props: { exact: true, path: '/home/payroll/GovernmentListingLHDN/CP159' },
    component: <CP159 />,
  },
  {
    props: { exact: true, path: '/home/payroll/bankintegrationfiles' },
    component: <BankIntegrationFiles />,
  },
  {
    props: {
      exact: true,
      path: '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing',
    },
    component: <BankIntegrationFilesListing />,
  },

  {
    props: { exact: true, path: '/home/payroll/HRDFListing' },
    component: <HRDFFundListing />,
  },
  {
    props: { exact: true, path: '/home/payroll/HRDFFundlisting/HRDFFundView' },
    component: <HRDFFundView />,
  },
  {
    props: { exact: true, path: '/home/payroll/HRDFListing/HRDFListingReport' },
    component: <HRDFFundListingByCompany />,
  },

  {
    props: { exact: true, path: '/home/payroll/Zakatlisting' },
    component: <ZakatListing />,
  },
  {
    props: { exact: true, path: '/home/payroll/Zakatlisting/ZakatListingView' },
    component: <ZakatListingView />,
  },

  //Bank Integration File - HSBC
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/HSBCSalaryText',
    },
    component: <HSBCSalaryText />,
  },

  //Bank Integration File - BIMB
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/BIMBSalaryText',
    },
    component: <BIMBSalaryText />,
  },

  //Bank Integration File - HLBB
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/HLBBSalaryExcel',
    },
    component: <HLBBSalaryExcel />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/HLBBSOCSOExcel',
    },
    component: <HLBBSOCSOExcel />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/HLBBEPFExcel',
    },
    component: <HLBBEPFExcel />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/HLBBLHDNExcel',
    },
    component: <HLBBLHDNExcel />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/HLBBTabungHajiExcel',
    },
    component: <HLBBTabungHajiExcel />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/HLBBZakatExcel',
    },
    component: <HLBBZakatExcel />,
  },

  //Bank Integration File - RHB
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/RHBExcel',
    },
    component: <RHBExcel />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/RHBIGBExcel',
    },
    component: <RHBIGBExcel />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/RHBEPFExcel',
    },
    component: <RHBEPFExcel />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/RHBEISExcel',
    },
    component: <RHBEISExcel />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/RHBLHDNExcel',
    },
    component: <RHBLHDNExcel />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/RHBSOCSOExcel',
    },
    component: <RHBSOCSOExcel />,
  },

  // Bank Intergration File - UOB
  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/UOBSalayDownloadText',
    },
    component: <UOBSalaryDownloadText />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/UOBEISText',
    },
    component: <UOBEISText />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/UOBSOCSOText',
    },
    component: <UOBSOCSOText />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/payroll/bankintegrationfiles/BankIntegrationFilesListing/UOBEPFText',
    },
    component: <UOBEPFText />,
  },
  

  //Personnel Report
  {
    props: { exact: true, path: '/home/personnel/employeebasicinfo' },
    component: <EmployeeBasicInfo />,
  },
  {
    props: { exact: true, path: '/home/personnel/employeeconfirmation' },
    component: <EmployeeConfirmation />,
  },
  {
    props: { exact: true, path: '/home/personnel/employeedependent' },
    component: <EmployeeDependent />,
  },
  {
    props: { exact: true, path: '/home/personnel/employeeeducation' },
    component: <EmployeeEducation />,
  },
  {
    props: { exact: true, path: '/home/personnel/employeenewjoin' },
    component: <EmployeeNewJoin />,
  },
  {
    props: { exact: true, path: '/home/personnel/employeeresignation' },
    component: <EmployeeResignation />,
  },

  //Leave Report

  {
    props: { exact: true, path: '/home/leave/leaveanalysis' },
    component: <LeaveAnalysis />,
  },

  {
    props: { exact: true, path: '/TimeAttendance/TimeAttendanceAnalysis' },
    component: <TimeAttendanceAnalysis />,
  },

  //CLAIM
  {
    props: { exact: true, path: '/home/claim/bankintegrationfiles' },
    component: <BankClaimIntegrationFiles />,
  },
  {
    props: {
      exact: true,
      path: '/home/claim/bankintegrationfiles/BankIntegrationFilesListing',
    },
    component: <BankClaimIntegrationFilesListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/claim/claimbalanceanalysis',
    },
    component: <ClaimBalanceAnalysis />,
  },

  {
    props: {
      exact: true,
      path: '/home/claim/claimstatusanalysis',
    },
    component: <ClaimStatusAnalysis />,
  },

  {
    props: {
      exact: true,
      path: '/home/claim/staffclaim',
    },
    component: <StaffClaim />,
  },

  //Bank Integration for CLAIM
  {
    props: {
      exact: true,
      path:
        '/home/claim/bankintegrationfiles/BankIntegrationFilesListing/HLBBClaimExcel',
    },
    component: <HLBBClaimExcel />,
  },

  //CIMB
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/CIMB/CIMBSalaryDownloadText'
    },
    component: <CIMBSalaryDownloadText/>
  },

  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/CIMB/CIMBEPFDownloadText'
    },
    component: <CIMBEPFDownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/CIMB/CIMBLHDNDownloadText'
    },
    component: <CIMBLHDNDownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/CIMB/CIMBSOCSODownloadText'
    },
    component: <CIMBSOCSODownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/CIMB/CIMBEISDownloadText'
    },
    component: <CIMBEISDownloadText/>
  },

  //MAYBANK
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2UBizSalaryDownload'
    },
    component: <M2UBizSalaryDownloadCsv/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCSalaryDownload'
    },
    component: <M2ERCSalaryDownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCEPFDownload'
    },
    component: <M2ERCEPFDownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCTaxDownload'
    },
    component: <M2ERCTaxDownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCSOCSODownload'
    },
    component: <M2ERCSOCSODownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCEISDownload'
    },
    component: <M2ERCEISDownloadText/>
  },
  {
    props: {
      path:
        '/home/Payroll/BankIntegrationFiles/MalayanBakingBerhad/M2ERCZakatDownload'
    },
    component: <M2ERCZakatDownloadText/>
  },
]

export default HomeRoutes
